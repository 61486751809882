<template>
  <div id="box">
    <el-container>
      <el-main>
        <el-tabs type="border-card">
          <el-tab-pane label="工业减负政策">
            <div
              id="container"
              v-for="(item, index) in dataList"
              :key="index"
              :class="{ on: index % 2 == 0, off: index % 2 != 0 }"
            >
              <h4 style="color: #1492ff">
                {{ item.title }}
              </h4>
              <br />
              <div class="demo1">
                <img src="../../assets/img/building.png" alt="" />
                <span>{{ item.content }}</span>
                <span style="font-size: 14px">{{ item.icon }}</span>
                <img src="../../assets/img/clock.png" alt="" />
                <span>{{ item.date }}</span>
              </div>
              <div class="demo2">
                <img src="../../assets/img/file.png" alt="" />
                <span class="appobj">{{ item.applyObj }}</span
                ><span>{{ item.applyObjValue }}</span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="开放型经济政策">
            <div
              id="container"
              v-for="(item, index) in dataList"
              :key="index"
              :class="{ on: index % 2 == 0, off: index % 2 != 0 }"
            >
              <h4 style="color: #1492ff">
                {{ item.title }}
              </h4>
              <br />
              <div class="demo1">
                <img src="../../assets/img/building.png" alt="" />
                <span>{{ item.content }}</span>
                <span style="font-size: 14px">{{ item.icon }}</span>
                <img src="../../assets/img/clock.png" alt="" />
                <span>{{ item.date }}</span>
              </div>
              <div class="demo2">
                <img src="../../assets/img/file.png" alt="" />
                <span class="appobj">{{ item.applyObj }}</span
                ><span>{{ item.applyObjValue }}</span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="人才政策">
            <div
              id="container"
              v-for="(item, index) in dataList"
              :key="index"
              :class="{ on: index % 2 == 0, off: index % 2 != 0 }"
            >
              <h4 style="color: #1492ff">
                {{ item.title }}
              </h4>
              <br />
              <div class="demo1">
                <img src="../../assets/img/building.png" alt="" />
                <span>{{ item.content }}</span>
                <span style="font-size: 14px">{{ item.icon }}</span>
                <img src="../../assets/img/clock.png" alt="" />
                <span>{{ item.date }}</span>
              </div>
              <div class="demo2">
                <img src="../../assets/img/file.png" alt="" />
                <span class="appobj">{{ item.applyObj }}</span
                ><span>{{ item.applyObjValue }}</span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="抗疫惠企政策">
            <div
              id="container"
              v-for="(item, index) in dataList"
              :key="index"
              :class="{ on: index % 2 == 0, off: index % 2 != 0 }"
            >
              <h4 style="color: #1492ff">
                {{ item.title }}
              </h4>
              <br />
              <div class="demo1">
                <img src="../../assets/img/building.png" alt="" />
                <span>{{ item.content }}</span>
                <span style="font-size: 14px">{{ item.icon }}</span>
                <img src="../../assets/img/clock.png" alt="" />
                <span>{{ item.date }}</span>
              </div>
              <div class="demo2">
                <img src="../../assets/img/file.png" alt="" />
                <span class="appobj">{{ item.applyObj }}</span
                ><span>{{ item.applyObjValue }}</span>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="”两直“资金政策">
            <div
              id="container"
              v-for="(item, index) in dataList"
              :key="index"
              :class="{ on: index % 2 == 0, off: index % 2 != 0 }"
            >
              <h4 style="color: #1492ff">
                {{ item.title }}
              </h4>
              <br />
              <div class="demo1">
                <img src="../../assets/img/building.png" alt="" />
                <span>{{ item.content }}</span>
                <span style="font-size: 14px">{{ item.icon }}</span>
                <img src="../../assets/img/clock.png" alt="" />
                <span>{{ item.date }}</span>
              </div>
              <div class="demo2">
                <img src="../../assets/img/file.png" alt="" />
                <span class="appobj">{{ item.applyObj }}</span
                ><span>{{ item.applyObjValue }}</span>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-input placeholder="请输入政策名称" prefix-icon="el-icon-search">
        </el-input>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataList: [
        {
          title: "龙港市新认定的国家专精特新“小巨人”企业奖补",
          content: "龙港市经济发展局",
          icon: "|",
          date: "2021年12月12日",
          applyObj: "申报对象 : ",
          applyObjValue: "新认定的国家专精特新“小巨人”企业",
        },
        {
          title: "龙港市工业企业新升规奖励申报指南",
          content: "龙港市经济发展局",
          icon: "|",
          date: "2021年09月14日",
          applyObj: "申报对象 : ",
          applyObjValue:
            "首次由规下升规上，且当年度纳税销售额达到2000万元的工业企业",
        },
        {
          title: "龙港市国家级、省级、市级企业研发中心认定（备案）奖励",
          content: "龙港市经济发展局",
          icon: "|",
          date: "2021年08月17日",
          applyObj: "申报对象 : ",
          applyObjValue: "新认定的国家级、省级、市级企业研发中心",
        },
        {
          title: "龙港市IT服务管理体系认证",
          content: "龙港市经济发展局",
          icon: "|",
          date: "2021年07月02日",
          applyObj: "申报对象 : ",
          applyObjValue: "通过IT服务管理体系认证的企业",
        },
        {
          title: "龙港市国家绿色印刷认证项目",
          content: "龙港市经济发展局",
          icon: "|",
          date: "2021年06月21日",
          applyObj: "申报对象 : ",
          applyObjValue: "首次获得国家绿色印刷认证的企业",
        },
        {
          title: "龙港市两化融合技改补助",
          content: "龙港市经济发展局",
          icon: "|",
          date: "2021年07月02日",
          applyObj: "申报对象 : ",
          applyObjValue: "实施两化融合技改项目的企业",
        },

        {
          title: "龙港市“龙腾计划”奖励申报指南",
          content: "龙港市经济发展局",
          icon: "|",
          date: "2021年12月12日",
          applyObj: "申报对象 : ",
          applyObjValue: "新认定的国家专精特新“小巨人”企业",
        },
      ],
    };
  },
};
</script>
<style scoped='scoped'>
* {
  margin: 0;
  padding: 0;
}

#container {
  height: 130px;
  position: relative;
  padding-left: 100px;
  margin-bottom: 10px;
}
.demo1 {
  width: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#container span {
  font-size: 10px;
}
.demo2 {
  margin-top: 15px;
}
.demo2 .appobj {
  margin-left: 25px;
}
.el-main {
  position: relative;
}
.el-input {
  top: 0;
  width: 200px;
  position: absolute;
  right: 100px;
  border: 1px solid #1492ff;
  border-radius: 5px;
}

.off {
  background: #eee;
  padding-top: 10px;
}
.el-tabs--border-card > .el-tabs__content {
  padding: 0px !important;
}
</style>